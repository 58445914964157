import {api, HTTP_STATUS} from '@nfq/typed-next-api';

import type {HTTP_METHODS, MutationRepoArgs} from '@nfq/typed-next-api';
import type {trackLinkClick} from 'ApiRoutes/tracking/click';
import type {trackpageView} from 'ApiRoutes/tracking/view';
import type {LinkClickTrackingBody} from 'Controllers/Tracking';
import type {TrackingAdapter} from 'Domain/adapters/TrackingService';
import type {PageViewTrackingBody} from 'Domain/entities/tracking';

/**
 * The `TrackingService` class provides methods for tracking link clicks and page views.
 * This class implements the `TrackingAdapter` interface.
 */
class TrackingService implements TrackingAdapter {
    /**
     * Tracks a link click event.
     * Sends a POST request to the `/api/tracking/click` endpoint with the details of the link click.
     *
     * @param key              The cache key for the request.
     * @param props            The properties object containing the request arguments.
     * @param props.arg        The arguments object containing the request body and method.
     * @param props.arg.body   The request body containing details of the link click.
     * @param props.arg.method The HTTP method for the request, expected to be POST.
     * @returns An object with the HTTP status of the request.
     *
     * @example
     * ```ts
     * const response = await TrackingService.trackLinkClick('key', {
     *   arg: {
     *     body: { external: true, url: 'https://example.com' },
     *     method: 'POST'
     *   }
     * });
     * console.log(response);
     * ```
     */
    async trackLinkClick(
        key: string, {arg: {body, method}}: MutationRepoArgs<LinkClickTrackingBody, HTTP_METHODS.POST>
    ) {
        try {
            await api<typeof trackLinkClick>('/api/tracking/click', {
                body: {
                    external: body.external,
                    url: body.url
                },
                method
            });

            return {status: HTTP_STATUS.OK};
        } catch (error) {
            return {status: HTTP_STATUS.BAD_REQUEST};
        }
    }

    /**
     * Tracks a page view event.
     * Sends a POST request to the `/api/tracking/view` endpoint with the details of the page view.
     *
     * @param key              The cache key for the request.
     * @param props            The properties object containing the request arguments.
     * @param props.arg        The arguments object containing the request body and method.
     * @param props.arg.body   The request body containing details of the page view.
     * @param props.arg.method The HTTP method for the request, expected to be POST.
     * @returns An object with the HTTP status of the request.
     *
     * @example
     * ```ts
     * const response = await TrackingService.trackPageView('key', {
     *   arg: {
     *     body: { firstView: true, url: 'https://example.com' },
     *     method: 'POST'
     *   }
     * });
     * console.log(response);
     * ```
     */
    async trackPageView(
        key: string, {arg: {body, method}}: MutationRepoArgs<PageViewTrackingBody, HTTP_METHODS.POST>
    ) {
        try {
            await api<typeof trackpageView>('/api/tracking/view', {
                body: {
                    firstView: body.firstView,
                    referrer: body.referrer,
                    referringMedia: body.referringMedia,
                    url: body.url,
                    viewport: body.viewport
                },
                method
            });

            return {status: HTTP_STATUS.OK};
        } catch (error) {
            return {status: HTTP_STATUS.BAD_REQUEST};
        }
    }
}

export default new TrackingService();