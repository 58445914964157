import {useCallback, useEffect} from 'react';

import {HTTP_METHODS} from '@nfq/typed-next-api';

import {useClickTracking} from 'Application/useCases/useTracking';

/**
 * Custom hook for tracking link clicks.
 * This hook adds an event listener to the document to track link clicks and send the tracking data to the API.
 *
 * @example
 * ```ts
 * useLinkTracking();
 * ```
 */
export const useLinkTracking = () => {
    const {trackLinkClick} = useClickTracking();

    /**
     * Handles click events on the document and tracks link clicks.
     * Extracts the URL from the clicked element and triggers the `trackLinkClick` function if a valid URL is found.
     *
     * @param event The mouse event triggered by clicking on the document.
     */
    const trackClick = useCallback((event: MouseEvent) => {
        if (!event.target) {
            return;
        }

        const target = event.target as HTMLElement;
        const {tagName} = target;

        let url: string | null = '';

        if (tagName === 'A') {
            url = target.getAttribute('href');
        }

        const {parentElement} = target;

        if (!url && parentElement?.tagName === 'A') {
            url = parentElement.getAttribute('href');
        }

        if (url) {
            void trackLinkClick({
                body: {
                    external: !url.startsWith('/'),
                    url
                },
                method: HTTP_METHODS.POST
            });
        }
    }, [trackLinkClick]);


    useEffect(() => {
        document.documentElement.addEventListener('click', trackClick);

        return () => {
            document.documentElement.removeEventListener('click', trackClick);
        };
    }, [trackClick]);
};