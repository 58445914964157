/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {useEffect, useState} from 'react';

import {useScreenSize} from '@nfq/react-grid';
import {HTTP_METHODS} from '@nfq/typed-next-api';
import {useRouter} from 'next/router';

import {usePageViewTracking} from 'Application/useCases/useTracking';

import type {Format, Funnels, Motiv} from 'Domain/entities/tracking';

/**
 * Custom hook for tracking page views.
 * This hook tracks the initial page view and subsequent page views, sending the data to the tracking API.
 *
 * @example
 * ```ts
 * useTrackingPageView();
 * ```
 */
export const useTrackingPageView = () => {
    const [firstView, setFirstView] = useState(true);
    const {trackPageView} = usePageViewTracking();
    const {route} = useRouter();
    const screen = useScreenSize();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        void trackPageView({
            body: {
                firstView,
                referrer: document.referrer,
                ...(params.size > 0 ? {
                    referringMedia: {
                        format: params.get('format') as Format,
                        funnel: params.get('funnel') as Funnels,
                        motive: params.get('motiv') as Motiv,
                        page: params.get('page')
                    }
                } : {}),
                url: route,
                viewport: screen
            },
            method: HTTP_METHODS.POST
        });

        if (firstView) {
            setFirstView(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);
};