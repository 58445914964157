import {darken, lighten, translucify} from '@nfq/react-grid';
import {createGlobalStyle, css} from 'styled-components';

import {chooseTheme} from 'UI/utils/helpers';

import type {Config} from '@nfq/react-grid';
import type {DefaultTheme} from 'styled-components';

const nfqgrid: Config = {
    baseSpacing: 0.4,
    columnGap: {
        lg: 24,
        md: 24,
        sm: 24,
        xl: 32,
        xs: 24,
        xxl: 32
    },
    container: {
        lg: 'fluid',
        md: 'fluid',
        sm: 'fluid',
        xl: 1240,
        xs: 'fluid',
        xxl: 1240
    },
    containerPadding: {
        lg: 32,
        md: 32,
        sm: 20,
        xl: 32,
        xs: 20,
        xxl: 64
    }
};

// FullScreen Container max width: 1920px - CONST "CONTAINER_FULLSIZE_WIDTH"

/**
 * Base colors used in the application.
 */
export const BaseColors = {
    /** Action color. ![#212325](https://via.placeholder.com/12/212325/212325.png) `#212325`. */
    actionColor: '#212325',
    /** Bg for black modules. ![#212325](https://via.placeholder.com/12/212325/212325.png) `#212325`. */
    blackBoxBg: '#212325',
    /** Card Background on white BG. ![#F5F5F5](https://via.placeholder.com/12/F5F5F5/F5F5F5.png) `#F5F5F5`. */
    cardBackgroundOnWhite: '#F5F5F5',
    /** Card body color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    cardBody: '#FFFFFF',
    /** Card head dark mode color. ![#37393B](https://via.placeholder.com/12/37393B/37393B.png) `#37393B`. */
    cardBorder: '#D8D8D8',
    /** Card head color. ![#141414](https://via.placeholder.com/12/141414/141414.png) `#141414`. */
    cardHead: '#141414',
    /** Card head dark mode color. ![#37393B](https://via.placeholder.com/12/37393B/37393B.png) `#37393B`. */
    cardHeadDarkMode: '#37393B',
    /** Distractor color. ![#F88D5A](https://via.placeholder.com/12/F88D5A/F88D5A.png) `#F88D5A`. */
    distractorColor: '#F88D5A',
    /** Focus color. ![#2B73FF](https://via.placeholder.com/12/2B73FF/2B73FF.png) `#2B73FF`. */
    focusColor: '#2B73FF',
    /** Footer background color. ![#040404](https://via.placeholder.com/12/040404/040404.png) `#040404`. */
    footerBgColor: '#040404',
    /** Hightlight color. ![#026471](https://via.placeholder.com/12/026471/026471.png) `#026471`. */
    hightlightColor: '#026471',
    /** Icon color. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    iconColor: '#000000',
    /** Dark Icon color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    iconColorDark: '#FFFFFF',
    /** Icon color light. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    iconColorLight: '#FFFFFF',
    /** Input background color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    inputBackground: '#FFFFFF',
    /** BG for linen colored box modules. ![#F4F0EA](https://via.placeholder.com/12/F4F0EA/F4F0EA.png) `#F4F0EA`. */
    linenBoxBg: '#F4F0EA',
    /** Page background color. ![#F5F5F5](https://via.placeholder.com/12/F5F5F5/F5F5F5.png) `#F5F5F5`. */
    pageBackground: '#F5F5F5',
    /** Page background dark mode color. ![#212325](https://via.placeholder.com/12/212325/212325.png) `#212325`. */
    pageBackgroundDarkMode: '#212325',
    /** Primary font color. ![#141414](https://via.placeholder.com/12/141414/141414.png) `#141414`. */
    primaryFontColor: '#141414',
    /** Primary font color light. ![#ffffff](https://via.placeholder.com/12/ffffff/ffffff.png) `#ffffff`. */
    primaryFontColorLight: '#ffffff',
    /** Secondary button color. ![#141414](https://via.placeholder.com/12/141414/141414.png) `#141414`. */
    secondaryButtonColor: '#141414',
    /** Secondary font dark mode color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    secondaryFontColorDarkMode: '#FFFFFF',
    /** Secondary button color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    secondaryInvertedButtonColor: '#FFFFFF',
    /** Switch color black. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    switchBlack: '#000000',
    /** Switch color black. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    switchWhite: '#FFFFFF',
    /** Thumbnail BG color. ![#EBEBEB](https://via.placeholder.com/12/EBEBEB/EBEBEB.png) `#EBEBEB`. */
    thumbnailBG: '#EBEBEB',
    /** Bg for video module. ![#E3E3E3](https://via.placeholder.com/12/E3E3E3/E3E3E3.png) `#E3E3E3`. */
    videoBackBg: '#E3E3E3'
} as const;

/**
 * Derived colors used in the application.
 * Use this for colors made with `darken`, `lighten` and `translucify`.
 */
export const DerivedColors = {
    /** Card background color. ![#F5F5F5](https://via.placeholder.com/12/F5F5F5/F5F5F5.png) `#F5F5F5`. */
    cardBackground: lighten(BaseColors.pageBackground, 100),
    /** Card background color. ![#F5F5F5](https://via.placeholder.com/12/F5F5F5/F5F5F5.png) `#F5F5F5`. */
    cardBackgroundDarkMode: translucify(BaseColors.pageBackground, 10),
    /** Card background on white dark mode color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    cardBackgroundOnWhiteDarkMode: translucify('#ffffff', 5),
    /** Card body dark mode color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    cardBodyDarkMode: translucify(BaseColors.cardBody, 5),
    /** Card hover color. ![#141414](https://via.placeholder.com/12/141414/141414.png) `#141414`. */
    cardHover: translucify(BaseColors.cardHead, 5),
    /** Card hover dark mode color. ![#37393B](https://via.placeholder.com/12/37393B/37393B.png) `#37393B`. */
    cardHoverDarkMode: lighten(BaseColors.cardHeadDarkMode, 5),
    /** Hightlight color with 40% opacity. ![#026471](https://via.placeholder.com/12/026471/026471.png) `#026471`. */
    headerBoxBg: translucify('#FFFFFF', 20),
    /** Hightlight color with 40% opacity. ![#026471](https://via.placeholder.com/12/026471/026471.png) `#026471`. */
    hightlightPointerColor: translucify(BaseColors.hightlightColor, 40),
    /** Input background dark mode color. ![#212325](https://via.placeholder.com/12/212325/212325.png) `#212325`. */
    inputBackgroundDarkMode: translucify(BaseColors.inputBackground, 5),
    /** Input background hover color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    inputBackgroundHover: translucify(BaseColors.primaryFontColor, 10),
    /** Input border color. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    inputBorder: translucify('#000000', 50),
    /** Input border dark mode color. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    inputBorderDarkMode: translucify(BaseColors.inputBackground, 50),
    /** Secondary font color. ![#737373](https://via.placeholder.com/12/737373/737373.png) `#737373`. */
    secondaryFontColor: lighten(BaseColors.primaryFontColor, 40.43),
    /** Secondary font color for card. ![#737373](https://via.placeholder.com/12/737373/737373.png) `#737373`. */
    secondaryFontColorCard: lighten(BaseColors.primaryFontColor, 40.43),
    /** Secondary font color for card dark mode. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    secondaryFontColorCardDarkMode: translucify('#ffffff', 60),
    /** Thumbnail BG dark mode color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    thumbnailBGDarkMode: translucify('#FFFFFF', 10),
    /** Bg for white modules. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    whiteBoxBg: lighten(BaseColors.pageBackground, 100),
    /** Bg for white modules dark mode color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    whiteBoxBgDarkMode: darken(BaseColors.pageBackgroundDarkMode, 20)
} as const;

export enum BoxShadows {
    /** The default dropshadow for Popovers. */
    popOverShadow = '0px 0px 10px 5px rgba(25, 38, 48, 0.05)'
}

export const theme: DefaultTheme = {
    boxShadows: BoxShadows,
    colors: {
        ...BaseColors,
        ...DerivedColors
    },
    nfqgrid
};

const createDarkVariables = css`
    --actionColor: ${theme.colors.primaryFontColorLight};
    --cardBackground: ${theme.colors.cardBackgroundDarkMode};
    --cardBackgroundOnWhite: ${theme.colors.cardBackgroundOnWhiteDarkMode};
    --cardBody: ${theme.colors.cardBodyDarkMode};
    --cardBorder: transparent;
    --cardHead: ${theme.colors.cardHeadDarkMode};
    --cardHover: ${theme.colors.cardHoverDarkMode};
    --headerBoxBg: ${theme.colors.pageBackgroundDarkMode};
    --iconColor: ${theme.colors.iconColorDark};
    --inputBackground: ${theme.colors.inputBackgroundDarkMode};
    --inputBorder: ${theme.colors.inputBorderDarkMode};
    --pageBackground: ${theme.colors.pageBackgroundDarkMode};
    --primaryFontColor: ${theme.colors.primaryFontColorLight};
    --secondaryButtonColor: ${theme.colors.secondaryInvertedButtonColor};
    --secondaryFontColor: ${theme.colors.secondaryFontColorDarkMode};
    --secondaryFontColorCard: ${theme.colors.secondaryFontColorCardDarkMode};
    --switchBlack: ${theme.colors.switchWhite};
    --switchWhite: ${theme.colors.switchBlack};
    --thumbnailBG: ${theme.colors.thumbnailBGDarkMode};
    --whiteBoxBg: ${theme.colors.whiteBoxBgDarkMode};
`;

interface GlobalStyleProps {
    $reactsToTheme?: boolean;
}

export const ThemeStyle = createGlobalStyle<GlobalStyleProps>`
    html, body {
        background-color: ${chooseTheme('pageBackground')};
    }
`;

export const GlobalStyle = createGlobalStyle`
    *,
    &::before,
    &::after {
        box-sizing: border-box;
    }

    * {
        -webkit-tap-highlight-color: transparent;
    }

    html {
        font-size: 10px;
        font-family: "aktiv-grotesk", Helvetica, Arial, sans-serif;
        --scroll-behavior: smooth !important;
        scroll-behavior: smooth !important;
    }

    html, body {
        color: ${({theme: globalTheme}) => globalTheme.colors.primaryFontColor};
        margin: 0;
        padding: 0;
        scroll-behavior: smooth;
        min-height: 100%;
        scroll-padding-top: 8rem;
    }

    #__next {
        min-height: 100%;
    }

    html[data-theme="dark"] {
        ${createDarkVariables}
    }


    @media (prefers-color-scheme: dark) {
        html:not([data-theme="light"]) {
            ${createDarkVariables}
        }
    }

    .ccm-widget.ccm--alignment--top .ccm-modal-inner, .ccm-widget.ccm--alignment--bottom .ccm-modal-inner {
        height: unset !important;
    }
`;