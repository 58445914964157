import {type ComponentType} from 'react';

import ReactDOMServer from 'react-dom/server';

import type {DefaultTheme, NFQGrid} from 'styled-components';

type ThemeColors = NFQGrid['gridColors'][keyof NFQGrid['gridColors']];


/**
 * The `iconAsBackground` function is designed to convert a React component representing an icon into a data URI string that can be used as a background image in CSS.
 * It takes a `ComponentType` representing the icon and an optional props object to be spread into the icon component.
 * This function is crucial for dynamically generating background images in styles, allowing for greater flexibility and customization in styling components.
 *
 * @param Icon  A `ComponentType` representing the React component of the icon to be converted.
 * @param props An optional object representing the props to be spread into the icon component.
 * @returns      A string representing the data URI of the rendered icon, which can be used as a background image in CSS.
 *
 * @example
 * ```tsx
 * const backgroundImage = iconAsBackground(MyIcon, { color: 'red' });
 * ```
 */
export const iconAsBackground = (Icon: ComponentType, props: object): string => `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    // eslint-disable-next-line react/jsx-filename-extension, react/jsx-props-no-spreading
    ReactDOMServer.renderToStaticMarkup(<Icon {...props} />)
)}`;

interface ChooseThemeType {
    $reactsToTheme?: boolean;
    theme: DefaultTheme;
}

/**
 * Chooses a theme color dynamically based on whether the component should react to theme changes.
 * This higher-order function returns a CSS variable reference or a direct theme color value.
 * It uses the provided `color` key to access the `DefaultTheme` color map, returning a CSS variable if `$reactsToTheme` is true,
 * which allows for reactive theme updates in styled-components. Otherwise, it returns the static color from the theme.
 *
 * @param color A key from the `DefaultTheme['colors']` indicating which color to use.
 * @returns A function that takes a `ChooseThemeType` object and returns the appropriate color string. This string can be a CSS variable or a direct color value from the theme.
 *
 * @example
 * ```tsx
 * // Example of using chooseTheme in a styled component that reacts to theme changes
 * const StyledComponent = styled.div`
 *   color: ${chooseTheme('primary')};
 * `;
 * // In this example, 'primary' refers to a color key in the theme object, and the component will use
 *   a CSS variable that reacts to changes in the theme's primary color.
 * ```
 */
export const chooseTheme = (color: keyof DefaultTheme['colors']) => (
    {$reactsToTheme, theme}: ChooseThemeType
): ThemeColors => {
    if ($reactsToTheme) {
        // eslint-disable-next-line security/detect-object-injection
        return `var(--${color}, ${theme.colors[color]})` as ThemeColors;
    }

    // eslint-disable-next-line security/detect-object-injection
    return theme.colors[color] as ThemeColors;
};

export type ColorTheme = 'dark' | 'light';

/**
 * Calculates the Haversine distance between two geographical points specified by their latitude and longitude.
 * The Haversine formula determines the shortest distance over the earth's surface, giving an "as-the-crow-flies" distance between the points.
 * This function converts the latitude and longitude values from degrees to radians and then applies the Haversine formula to compute the distance.
 * The result is returned in kilometers, rounded to the nearest kilometer.
 *
 * @param lat1 The latitude of the first point in degrees.
 * @param lon1 The longitude of the first point in degrees.
 * @param lat2 The latitude of the second point in degrees.
 * @param lon2 The longitude of the second point in degrees.
 * @returns The Haversine distance between the two points in kilometers.
 *
 * @example
 * ```ts
 * const distance = haversineDistanceBetweenPoints(51.5074, -0.1278, 48.8566, 2.3522);
 * console.log(distance); // Output: the distance in kilometers between London and Paris
 * ```
 */
export const haversineDistanceBetweenPoints = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    /* eslint-disable @nfq/no-magic-numbers */
    const R = 6371e3;
    const p1 = (lat1 * Math.PI) / 180;
    const p2 = (lat2 * Math.PI) / 180;
    const deltaLon = lon2 - lon1;
    const deltaLambda = (deltaLon * Math.PI) / 180;
    const d = Math.acos(
        Math.sin(p1) * Math.sin(p2) + Math.cos(p1) * Math.cos(p2) * Math.cos(deltaLambda)
    ) * R;

    return Math.round(d) / 1000;
    /* eslint-enable @nfq/no-magic-numbers */
};