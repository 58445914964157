import {useLinkTracking} from 'UI/hooks/useLinkTracking';
import {useTrackingPageView} from 'UI/hooks/useTracking';

import type {WithChildren} from 'types/global';


/**
 * The `TrackingWrapper` component is a wrapper that provides link click and page view tracking functionality.
 * It uses custom hooks to track page views and link clicks throughout the application.
 *
 * @param props          The component props.
 * @param props.children The child elements to be wrapped by the `TrackingWrapper`.
 * @returns A React element that wraps the child elements and provides tracking functionality.
 *
 * @example
 * ```tsx
 * <TrackingWrapper>
 *   <YourComponent />
 * </TrackingWrapper>
 * ```
 */
export const TrackingWrapper = ({children}: WithChildren) => {
    useTrackingPageView();
    useLinkTracking();

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};