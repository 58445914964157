import {useMutateRepository} from '@nfq/typed-next-api';

import TrackingService from 'Application/services/TrackingService';

/**
 * Custom hook for tracking link click events using the `TrackingService`.
 * This hook uses the `useMutateRepository` hook to trigger the `trackLinkClick` method of the `TrackingService`,
 * which tracks link click events by sending the necessary data to the API.
 *
 * @returns An object containing the `trackLinkClick` function that triggers the link click tracking operation.
 *
 * @example
 * ```ts
 * const {trackLinkClick} = useClickTracking();
 * trackLinkClick({
 *   arg: {
 *     body: { external: true, url: 'https://example.com' },
 *     method: 'POST'
 *   }
 * });
 * ```
 */
export const useClickTracking = () => {
    const {trigger} = useMutateRepository<typeof TrackingService.trackLinkClick>(
        'TrackLinkClick',
        TrackingService.trackLinkClick,
        {revalidate: true}
    );

    return {trackLinkClick: trigger};
};

/**
 * Custom hook for tracking page view events using the `TrackingService`.
 * This hook uses the `useMutateRepository` hook to trigger the `trackPageView` method of the `TrackingService`,
 * which tracks page view events by sending the necessary data to the API.
 *
 * @returns An object containing the `trackPageView` function that triggers the page view tracking operation.
 *
 * @example
 * ```ts
 * const {trackPageView} = usePageViewTracking();
 * trackPageView({
 *   arg: {
 *     body: { firstView: true, url: 'https://example.com' },
 *     method: 'POST'
 *   }
 * });
 * ```
 */
export const usePageViewTracking = () => {
    const {trigger} = useMutateRepository<typeof TrackingService.trackPageView>(
        'TrackPageView',
        TrackingService.trackPageView,
        {revalidate: true}
    );

    return {trackPageView: trigger};
};